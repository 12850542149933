const Admin = () => import('../pages/loginPage.vue')
const IndexView = () => import('../components/adminDashboard/indexView.vue')
const articlePage = () => import('../pages/articlesPage.vue')
const galleryPage = () => import('../pages/galleryPage.vue')
const eventPage = () => import('../pages/eventPage.vue')
const tractPage = () => import('../pages/tractPage.vue')
const streamPage = () => import('../pages/streamPage.vue')
const viewArticle = () => import('../components/adminDashboard/viewArticle.vue')
const addArticles = () => import('../components/adminDashboard/addArticles.vue')
const addGallery = () => import('../components/adminDashboard/addGallery.vue')
const addEvent = () => import('../components/adminDashboard/addEvent.vue')
const viewGallery = () => import('../components/adminDashboard/addGallery.vue')

const routes = [{
  path: '/admin',
  name: 'admin',
  component: Admin,
  meta: {
    layout: 'AdminLayout',
    title: 'Home | Church Of Christ Rumuomasi'
  }
},
{
  path: '/dashboard',
  name: 'dashboard',
  component: IndexView,
  meta: {
    layout: 'AdminLayout',
    requiresAuth: true,
    title: 'Admin | Church Of Christ Rumuomasi'
  },
  children: [{
    path: 'articlePage',
    name: 'articlePage',
    component: articlePage,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Articles | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'articlePage/:id',
    name: 'view-article',
    component: viewArticle,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'View Article | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'galleryPage',
    name: 'galleryPage',
    component: galleryPage,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Articles | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'eventPage',
    name: 'eventPage',
    component: eventPage,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Events | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'articlePage/:id',
    name: 'view-gallery',
    component: viewGallery,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'View Gallery | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'addArticles',
    name: 'add-articles',
    component: addArticles,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Add Article | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'addGallery',
    name: 'add-gallery',
    component: addGallery,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Add Gallery | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'addEvent',
    name: 'add-event',
    component: addEvent,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Add Event | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'addTract',
    name: 'add-tract',
    component: tractPage,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Add Event | Church Of Christ Rumuomasi'
    }
  },
  {
    path: 'addStream',
    name: 'add-stream',
    component: streamPage,
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      title: 'Add Event | Church Of Christ Rumuomasi'
    }
  }
  ]
}
]
export default routes
